import { Component } from 'solid-js'
import { ButtonProps } from '~/components/button/button.interfaces'
import styles from '~/components/button/button.module.scss'
import LoadingIcon from '~/assets/icons/loading.svg'
import { translations } from '~/translations'

const Button: Component<ButtonProps> = ({ children, style, type='button', onClick, isLoading, ...props }) => {
  const Icon = props.icon

  const classList = {
    [`${styles.button}`]: true,
    [`${styles.loading}`]: isLoading
  }

  if(style){
    classList[styles[style]] = true
  }

  return (
    <button
      classList={classList}
      type={type}
      onClick={onClick}
      disabled={isLoading}
    >
      {Icon && (
        <Icon class={styles.icon} />
      )}
      {children && !isLoading && (
        <div class={styles.label}>
          {children}
        </div>
      )}
      {children && isLoading && (
        <div class={styles.label}>
          {translations().loading.default}
        </div>
      )}
      {isLoading && (
        <LoadingIcon class={styles.loadingIcon} />
      )}
    </button>
  )
}

export default Button