import { createSignal } from 'solid-js'
import { Settings } from '~/services/settings/settings.interfaces'
import { Language } from '~/translations/types'

const defaultSettings: Settings = {
  darkmode: typeof window !== 'undefined' ? window.localStorage.getItem('darkmode') === "true" : false,
  language: typeof window !== 'undefined' ? window.localStorage.getItem('language') as Language ?? 'fr' : 'fr'
}

const [settings, setSettings] = createSignal(defaultSettings)

const updateDarkmode = (enabled: boolean) => {
  if(typeof window !== 'undefined'){
    const classList = window.document.body.classList
    if (enabled){
      classList.add('darkmode')
    }else{
      classList.remove('darkmode')
    }
  }
}

const updateSettings = (newSettings: Partial<Settings>) => {
  for(const setting in newSettings){
    window.localStorage.setItem(setting, newSettings[setting as keyof Settings] as string)
  }
  setSettings({
    ...settings(),
    ...newSettings
  })
  console.log(settings())
}

export {
  settings,
  updateSettings,
  updateDarkmode
}