import { countable, variables } from '~/translations/utils'

const enDictionnary = {
  "header": {
    "menu": {
      "series": "Series",
      "clients": "Customers",
      "account": "Account"
    },
    "subscribe": "Subscribe",
    "subscription": "Subscription"
  },
  "notifications": {
    "new": "New",
    "notifications": countable({ zero: 'No notification', one: '{count} notification', many: '{count} notifications'}),
    "default": "Notification",
    "invite": "Invite",
    "inviteConfirmation": {
      "title": variables('Join the project {title}?'),
      "confirm": "Join",
      "reject": "Reject"
    },
    "update": "Update"
  },
  "general": {
    "actions": {
      "add": "Add",
      "create": "Create",
      "edit": "Edit",
      "delete": "Delete",
      "invite": "Invite",
      "export": "Export",
      "stop": "Stop",
      "confirm": "Confirm",
      "insert": "Insert",
      "send": "Send",
      "cancel": "Cancel",
      "display": "Display",
      "save": "Save",
      "copy": "Copy",
      "import": "Import",
      "filter": "Filter",
      "search": "Search",
      "fold": "Fold",
      "replace": "Replace",
      "replaceBy": "Replace by",
      "download": "Download",
      "updated": "Updated",
      "back": "Back",
      "prev": "Previous",
      "next": "Next"
    },
    "search": {
      "noResult": "No result",
      "resultIndex": variables('Result {current}/{total}'),
      "replaceConfirmation": countable({ zero: 'No entry will be replaced', one: 'Replace {count} entry', many: 'Replace {count} entries'})
    },
    "fields": {
      "findInContacts": "Find in my contacts list",
      "optional": "Optional"
    },
    "errors": {
      "noEmpty": variables('{field} must be filled')
    }
  },
  "login": {
    "title": "Log-in",
    "email": "Email",
    "emailPlaceholder": "email@exemple.com",
    "password": "Password",
    "login": "Log-in",
    "register": "Register",
    "forgotPassword": "Forgot password"
  },
  "register": {
    "title": "Register",
    "register": "Register",
    "password": "Password",
    "confirmPassword": "Confirm password",
    "alreadyHaveAccount": "Already registered"
  },
  "forgotPassword": {
    "title": "Reset password",
    "instructions": "An email with instruction for resetting your password has been sent."
  },
  "resetPassword": {
    "title": "Reset password",
    "newPassword": "New password",
    "success": "Your password has been changed successfully."
  },
  "onboarding": {
    "welcome": "Welcome!",
    "createPassword": "Create password",
    "invitePeople": "Invite partners",
    "done": "Done",
    "welcome1": "Ready to join ZOON universe",
    "welcome2": "Create your first series or join a partner's project!",
    "begin": "Start"
  },
  "dashboard": {
    "user": {
      "welcome": "Welcome",
      "currentRole": "Current role"
    },
    "contacts": {
      "title": "Partners",
      "savedContacts": {
        "title1": "Your list",
        "title2": "Favorites",
        "emptyState": "No favorites",
        "remove": "Delete from Favorites"
      },
      "recentContacts": {
        "title1": "From your recent projects",
        "title2": "Recent",
        "add": "Add to Favorites"
      },
      "invite": {
        "title": "Add a partner",
        "email": "Partner's email",
        "emailDescription": "Add your contacts with their ZOON email",
        "errorNoResponse": "This partner doesn't exist or doesn't accept invites",
        "errorAlreadyExists": "Partner already added"
      }
    },
    "wip": {
      "title": "Ongoing chapters",
      "series": "Series",
      "chapter": "Chapter",
      "progress": "Progress",
      "lastUpdate": "Last update",
      "emptyState": {
        "title": "No ongoing chapter",
        "description": "Create a series or add a chapter to your ongoing series to see their progress."
      }
    },
    "discord": {
      "title1": "Join us on",
      "title2": "Discord"
    }
  },
  "loading": {
    "default": "Loading",
    "uploadingImages": "Uploading images",
    "transcription": "Transcribing document",
    "transcriptionImage": "Image",
    "transcriptionOn": "on",
    "exportingTranslations": "Exporting translations"
  },
  "editor": {
    "translation": {
      "header": {
        "originalWordCount": "Source",
        "targetWordCount": "Target",
        "words": "words",
        "chars": "char."
      },
      "filterOptions": {
        "all": "All",
        "corrections": "Edits",
        "comments": "Comments",
        "onomatopoeias": "Onomatopoeias"
      },
      "entries": {
        "markAsOnomatopoeia": "Label as onomatopoeia",
        "originalText": "Original text",
        "translationText": "Translation",
        "deleteConfirmation": {
          "title": "Delete this translated entry?"
        },
        "emptyState": {
          "label": "No translation",
          "description": "Define a zone on the document or click on a balloon to create your first translation"
        },
        "filteredEmptyState": {
          "label": "No matching translation found",
          "description": "Switch filters to see the other translations"
        }
      }
    },
    "typesetting": {
      "exampleText": "Example test",
      "textStyle": {
        "notSaved": "Unregistered style",
        "deleteConfirmation": {
          "title": "Delete text style?"
        },
        "emptyState": {
          "title": 'No text styles available'
        },
        "form": {
          "create": {
            "title": "Create new text style",
            "name": "Style name",
            "placeholder": "Dialog/Default",
            "defaultValue": "Style/Variation1"
          }
        }
      },
      "fonts": {
        "missingFont": "Missing font"
      },
      "fields": {
        "fontSize": "Size",
        "uppercase": "Caps",
        "superscript": "Superscript",
        "lineHeight": "Line space",
        "letterSpacing": "Letters",
        "color": "Color",
        "gradient": "Scaling",
        "addGradient": "Add scaling",
        "removeGradient": "Delete scaling",
        "strokes": "Strokes",
        "shadows": "Shades"
      }
    },
    "comments": {
      "view": "See comments",
      "add": "Add comment",
      "placeholder": "Your comment",
      "markAsResolved": "Mark as resolved",
      "count": countable({ zero: 'No comment', one: '{count} comment', many: '{count} comments'}),
      "deleteConfirmation": {
        "title": "Delete this comment?",
        "description": "Delete is final"
      }
    },
    "io": {
      "exportTranslations": "Export translations",
      "importTranslations": "Import translations",
      "exportTypesetting": "Export lettering",
      "documentation": 'Documentation',
      "preview": 'Preview',
      "forms": {
        "importTranslations": {
          "title": "Import a translation",
          "description": "Import a translation from a file complying with our format.",
          "translationsFile": "Translation file",
          "overwriteTranslations": "Overwrite translations",
          "overwriteTypesets": "Overwrite lettering"
        }
      }
    },
    "page": "page",
    "steps": {
      "translation": "Translation",
      "proofreading": "Copyediting",
      "typesetting": "Lettering",
      "typeProofreading": "Proofreading",
      "validation": "Validation",
      "completed": "Completed",
      "archived": "Archived"
    },
    "resumeStep": {
      "translation": "Resume translating",
      "proofreading": "Resume copyediting",
      "typesetting": "Resume lettering",
      "validation": "Chapter validation",
      "completed": "Overview"
    },
    "stepConfirmations": {
      "validateTranslation": {
        "title": "Confirm translation",
        "description": "Chapter will be assigned for copyediting."
      },
      "validateProofreading": {
        "title": "Confirm copyediting",
        "description": "Chapter will be assigned for lettering."
      },
      "backToTranslation": {
        "title": "Send back to translation",
        "description": "Chapter will be assigned for translation."
      },
      "validateTypesetting": {
        "title": "Confirm lettering",
        "description": "Chapter will be assigned for proofreading."
      },
      "validateTypeProofreading": {
        "title": "Confirm proofreading",
        "description": "Change status chapter to final validation."
      },
      "backToTypesetting": {
        "title": "Send back to lettering",
        "description": "Chapter will be assigned for lettering."
      },
      "validateValidation": {
        "title": "Approve chapter",
        "description": "Chapter will be marked as finished. You can export it in several formats."
      }
    }
  },
  "series": {
    "create": "Create a series",
    "title": "Series list",
    "client": "Customer\r\n",
    "series": "Series",
    "chapters": "Chapters",
    "lastUpdate": "Last update",
    "author": "Author",
    "byAuthor": "by",
    "chapterCount": countable({ zero: 'No chapter', one: '{count} chapter', many: '{count} chapters'}),
    "emptyState": {
      "title": "No series",
      "description": "Start a new series"
    },
    "deleteConfirmation": {
      "title": "Delete this series?",
      "description": "Delete is final"
    },
    "team": {
      "title": "Team",
      "updateModal": {
        "title": variables('Update {user} role'),
        "role": "Assigned role"
      },
      "deleteModal": {
        "title": variables('Delete {user} from the project?')
      },
      "invite": {
        "title": "Invite a partner",
        "email": "Partner email",
        "role": "Assigned role"
      }
    },
    "forms": {
      "create": {
        "title": "Create a new series",
        "client": "Customer",
        "seriesTitle": "Series title",
        "author": "Author",
        "errors": {
          "noRolesAttributed": 'You must set one of the role slots before creating a series.'
        } 
      },
      "update": {
        "title": "Update series",
        "client": "Customer",
        "seriesTitle": "Series title",
        "author": "Author"
      }
    }
  },
  "chapters": {
    "create": "Create a chapter",
    "title": "Chapters list",
    "lastUpdate": "Last update",
    "row": {
      "chapter": "Chapter",
      "exportTranslation": "Export translation",
      "exportTypesetting": "Export lettering",
      "downloadAssets": "Download resources"
    },
    "emptyState": {
      "title": "No chapter",
      "description": "Start translating by adding a chapter"
    },
    "setStep": {
      "title": 'Modifier lʼétape de travail',
      "errors": {
        "noCollaborator": 'No collaborator has this role. Add someone to that role to set this chapter to this step.'
      }
    },
    "deleteConfirmation": {
      "title": "Delete this chapter?",
      "description": "Delete is final"
    },
    "forms": {
      "create": {
        "title": "Create a new chapter",
        "chapterIndex": "Chapter number",
        "chapterTitle": "Chapter title",
        "sourcePages": "Source pages",
        "targetPages": "Target pages",
        "guidelines1": "Your files will be sorted in alphabetical order, depending on their file name. Example:",
        "guidelines2": "Maximum height per file",
        aboutTeam: variables('With your current team, the chapter will start at the {step} step')
      },
      "update": {
        "title": "Create a new chapter",
        "assetsLabel": "Resources archives",
        "assetsDescription": "Share here a resource zip file for lettering"
      },
      "export": {
        "title": "Export chapter",
        "widthPreset": "Width",
        "widthValue": "Value",
        "splitIntoChunks": "Split into small images",
        "splitBy": "Split in blocks of",
        "fileFormat": "File format"
      }
    }
  },
  "user": {
    "role": {
      "translation": "Translation",
      "proofreading": "Copyediting",
      "typesetting": "Lettering",
      "admin": "Creation"
    }
  },
  "subscriptions": {
    "plans": {
      "brand": "ZOON",
      "brandPro": "ZOON company",
      "single": "Single",
      "duo": "Duo",
      "linguist": "Linguist",
      "graphist": "Letterer",
      "or": "or"
    },
    "recurrence": {
      "singleMonth": "month",
      "severalMonths": "month",
      "year": "year",
      "forEach": "per"
    },
    "renewal": {
      "nextPayment": "Next renewal",
      "schedule": 'Schedule',
      "endsOn": "Will end on",
      "cancelled": "Canceled"
    },
    "taxesAtNextStep": "Tax will be applied at the next step.",
    "priceUpToQuantity": "Price up to",
    "priceOverQuantity": "Group rate",
    "tax": "Tax",
    withoutTax: 'HT',
    existingSubscriptionCredit: variables('{subscription} (Proration credit)'),
    newSubscriptionUpgrade: variables('{subscription} (Upgrade)'),
    "total": "Total",
    "indie": {
      "title": "Individual subscription",
      "subscribe": {
        "cta": "Subscribe",
        "title": "Our indie zooners prices"
      },
      "upgrade": {
        "cta": "Update",
        "title": "Update your subscription",
        "newPlan": "New subscription",
        "confirmImmediateBilling": "By ticking this box, you agree to be invoiced immediately to upgrade your plan.",
        "confirmDowngrade": variables('By ticking this box, you agree to be invoiced at the end of your plan ({date}), in order to update your plan.')
      },
      "emptyState": "No subscription",
      "cancelConfirmation": {
        "title": variables('Cancel your subscription {plan}?'),
        "description": variables('Subscription will end on {date}')
      }
    },
    "pro": {
      "title": "Subscriptions company",
      "subscribe": {
        "cta": "Subscribe",
        "title": "Our company prices",
        "submit": variables('Subscribe {count} licenses')
      },
      "upgrade": {
        "cta": "Update my licenses",
        "title": "Update my company licenses",
        "submit": variables('Upgrade for {count} licenses'),
        "currentSubscription": "Current subscription",
        "upgradedSubscription": "Updated subscription"
      },
      "emptyState": "No company subscription",
      "cancelConfirmation": {
        "title": "Cancel your company subscription?",
        "description": variables('Licenses will end on {date}')
      },
      "licenses": {
        "licenses": "Licenses ",
        "emptyState": "No assigned license",
        "count": countable({ zero: 'No license', one: '{count} license', many: '{count} ilcenses'}),
        "noSlotsAvailable": "No slot available",
        "grant": "Assign ",
        "grantSlot": "Assign a license",
        "deleteConfirmation": {
          "title": variables('Revoke assigned license to {name}?'),
          "description": "The slot will be available for assigning a new partner.",
          "confirm": "Revoke license"
        }
      }
    }
  },
  "account": {
    "profile": {
      "profile": "Profile",
      "preferredRole": "Favorite role",
      "email": "Email",
      "firstName": "First name",
      "lastName": "Last name",
      "language": "Language",
      "interface": "Interface",
      "theme": {
        "light": "Light",
        "dark": "Dark"
      },
      "logout": "Log-out"
    },
    "invoices": {
      "invoices": "Invoices"
    },
    "contacts": {
      "contacts": "My partners"
    }
  },
  "errors": {
    "notFound": {
      "title": "This page can't be found",
      "description": "This content doesn't exist.",
      "back": "Back"
    },
    "fatalError": {
      "title": "An error occurred",
      "description": "Details have been sent to our technical team, we will do everything in our power to fix this mishap as fast as possible.",
      "back": "Back"
    }
  },
  "footer": {
    "legal": 'Terms and Conditions'
  }
}

export default enDictionnary