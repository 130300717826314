import { countable, variables } from '~/translations/utils'

const krDictionnary = {
  "header": {
    "menu": {
      "series": "시리즈",
      "clients": "클라이언트",
      "account": "계정"
    },
    "subscribe": "구독하기",
    "subscription": "구독 내역/플랜"
  },
  "notifications": {
    "new": "신규",
    "notifications": countable({ zero: '공지 없음', one: '{count}개의 공지', many: '{count}개의 공지'}),
    "default": "공지사항",
    "invite": "초청",
    "inviteConfirmation": {
      "title": variables('{title} 프로젝트에 조인하시겠습니까?'),
      "confirm": "조인하기",
      "reject": "거절하기"
    },
    "update": "업데이트"
  },
  "general": {
    "actions": {
      "add": "추가",
      "create": "신규",
      "edit": "편집",
      'confirm': 'Confirm',
      "delete": "삭제",
      "invite": "초대",
      "export": "내보내기",
      "stop": "중단",
      "insert": "삽입",
      "send": "보내기",
      "cancel": "취소",
      "display": "표시",
      "save": "저장",
      "copy": "복사",
      "import": "가져오기",
      "filter": "필터",
      "search": "검색",
      "fold": "접기",
      "replace": "대체",
      "replaceBy": "~로 대체",
      "download": "다운로드",
      "updated": "업데이트 완료",
      "back": "돌아가기",
      "prev": "이전",
      "next": "다음"
    },
    "search": {
      "noResult": "결과 없음",
      "resultIndex": variables('{current}/{total}개의 결과'),
      "replaceConfirmation": countable({ zero: '변경된 내용이 없습니다', one: '{count}개의 내용을 변경합니다', many: '{count}개의 내용을 변경합니다'})
    },
    "fields": {
      "findInContacts": "연락처 내 검색",
      "optional": "선택사항"
    },
    "errors": {
      "noEmpty": variables('{field}을 입력해야 합니다')
    }
  },
  "login": {
    "title": "로그인",
    "email": "이메일",
    "emailPlaceholder": "email@exemple.com",
    "password": "비밀번호",
    "login": "로그인",
    "register": "회원가입",
    "forgotPassword": "비밀번호 찾기"
  },
  "register": {
    "title": "계정 생성",
    "register": "계정 생성",
    "password": "비밀번호",
    "confirmPassword": "비밀번호 확인",
    "alreadyHaveAccount": "기존 회원"
  },
  "forgotPassword": {
    "title": "비밀번호 초기화",
    "instructions": "비밀번호 초기화를 위한 이메일이 전송되었습니다."
  },
  "resetPassword": {
    "title": "비밀번호 초기화",
    "newPassword": "신규 비밀번호",
    "success": "비밀번호가 정상적으로 변경되었습니다"
  },
  "onboarding": {
    "welcome": "환영합니다!",
    "createPassword": "비밀번호 생성",
    "invitePeople": "파트너 초대",
    "done": "완료",
    "welcome1": "Zoon 세상에 조인할 준비를 마치셨습니다",
    "welcome2": "첫 시리즈를 생성하거나 파트너의 프로젝트에 조인하세요!",
    "begin": "시작하기"
  },
  "dashboard": {
    "user": {
      "welcome": "환영합니다",
      "currentRole": "현 역할"
    },
    "contacts": {
      "title": "파트너",
      "savedContacts": {
        "title1": "파트너 목록",
        "title2": "자주 찾는 연락처",
        "emptyState": "자주 찾는 연락처 목록이 없습니다",
        "remove": "자주 찾는 목록에서 제외"
      },
      "recentContacts": {
        "title1": "최근 프로젝트의 협업 파트너 연락처",
        "title2": "최근 연락처",
        "add": "자주 찾는 목록에 추가하기"
      },
      "invite": {
        "title": "파트너 추가하기",
        "email": "파트너 이메일",
        "emailDescription": "Zoon 이메일로 연락처에 추가",
        "errorNoResponse": "존재하지 않거나 초대 기능을 비활성화한 파트너입니다",
        "errorAlreadyExists": "이미 등록된 파트너입니다"
      }
    },
    "wip": {
      "title": "작업중인 챕터",
      "series": "시리즈",
      "chapter": "챕터",
      "progress": "진행상황",
      "lastUpdate": "마지막 업데이트 (일자)",
      "emptyState": {
        "title": "작업 중인 챕터가 없습니다",
        "description": "시리즈 생성 또는 챕터 추가시 이곳에서 진행상황을 확인할 수 있습니다"
      }
    },
    "discord": {
      "title1": "Discord",
      "title2": "에서 만나요"
    }
  },
  "loading": {
    "default": "로딩중",
    "uploadingImages": "이미지 업로드",
    "transcription": "문서 내 글자 로딩중",
    "transcriptionImage": "이미지",
    "transcriptionOn": "/",
    "exportingTranslations": "번역물 내보내기"
  },
  "editor": {
    "translation": {
      "header": {
        "originalWordCount": "원문",
        "targetWordCount": "번역문",
        "words": "단어",
        "chars": "글자"
      },
      "filterOptions": {
        "all": "전체",
        "corrections": "수정",
        "comments": "커멘트",
        "onomatopoeias": "의성어"
      },
      "entries": {
        "markAsOnomatopoeia": "의성어로 분류",
        "originalText": "원문",
        "translationText": "번역",
        "deleteConfirmation": {
          "title": "이 번역 항목을 삭제하시겠습니까?"
        },
        "emptyState": {
          "label": "번역 없음",
          "description": "문서 내 구역을 그리거나 물풍선을 클릭하여 번역을 시작하세요"
        },
        "filteredEmptyState": {
          "label": "해당하는 번역이 없습니다",
          "description": "나머지 번역 확인을 위해 필터를 변경하세요"
        }
      }
    },
    "typesetting": {
      "exampleText": "예시 텍스트",
      "textStyle": {
        "notSaved": "저장되지 않은 스타일",
        "deleteConfirmation": {
          "title": "해당 텍스트 스타일을 삭제하시겠습니까?"
        },
        "emptyState": {
          "title": 'No text styles saved'
        },
        "form": {
          "create": {
            "title": "신규 텍스트 스타일 생성",
            "name": "텍스트 스타일명",
            "placeholder": "대화/디폴트",
            "defaultValue": "스타일/변형1"
          }
        }
      },
      "fonts": {
        "missingFont": "해당 글꼴 없음"
      },
      "fields": {
        "fontSize": "글자 크기",
        "uppercase": "대문자",
        "superscript": "위첨자",
        "lineHeight": "줄 간격",
        "letterSpacing": "글자",
        "color": "색상",
        "gradient": "그레이디언트",
        "addGradient": "그레이디언트 추가",
        "removeGradient": "그레이디언트 삭제",
        "strokes": "여백",
        "shadows": "그림자"
      }
    },
    "comments": {
      "view": "커멘트 보기",
      "add": "커멘트 추가",
      "placeholder": "내 커멘트",
      "markAsResolved": "해결된 것으로 표시",
      "count": countable({ zero: '커멘트 없음', one: '{count}개의 커멘트', many: '{count}개의 커멘트'}),
      "deleteConfirmation": {
        "title": "해당 커멘트를 삭제하시겠어요?",
        "description": "삭제 후 되돌릴 수 없습니다"
      }
    },
    "io": {
      "exportTranslations": "번역 내보내기",
      "importTranslations": "번역 불러오기",
      "exportTypesetting": "레터링 내보내기",
      "documentation": 'Documentation',
      "preview": 'Preview',
      "forms": {
        "importTranslations": {
          "title": "번역 불러오기",
          "description": "지원되는 텍스트 포맷의 번역 파일만 불러올 수 있습니다",
          "translationsFile": "번역 파일",
          "overwriteTranslations": "번역 덮어쓰기",
          "overwriteTypesets": "레터링 덮어쓰기"
        }
      }
    },
    "page": "페이지",
    "steps": {
      "translation": "번역",
      "proofreading": "감수",
      "typesetting": "레터링",
      "typeProofreading": "레터링 수정",
      "validation": "확인",
      "completed": "완료",
      "archived": "보관됨"
    },
    "resumeStep": {
      "translation": "번역 계속하기",
      "proofreading": "감수 계속하기",
      "typesetting": "레터링 계속하기",
      "validation": "챕터 승인하기",
      "completed": "미리보기"
    },
    "stepConfirmations": {
      "validateTranslation": {
        "title": "번역 확정",
        "description": "해당 챕터를 감수 단계로 넘깁니다"
      },
      "validateProofreading": {
        "title": "감수 확정",
        "description": "해당 챕터를 레터링 단계로 넘깁니다"
      },
      "backToTranslation": {
        "title": "번역 돌려보내기",
        "description": "해당 챕터를 번역 단계로 돌려보냅니다"
      },
      "validateTypesetting": {
        "title": "레터링 끝내기",
        "description": "해당 챕터를 레터링 수정 단계로 넘깁니다"
      },
      "validateTypeProofreading": {
        "title": "수정 끝내기",
        "description": "해당 챕터를 최종 승인합니다"
      },
      "backToTypesetting": {
        "title": "레터링 단계로 돌려보내기",
        "description": "해당 챕터를 레터링 단계로 돌려보냅니다"
      },
      "validateValidation": {
        "title": "챕터 승인",
        "description": "해당 챕터 작업은 완료된 것으로 표시됩니다. 다양한 포맷으로 내보내기가 가능합니다."
      }
    }
  },
  "series": {
    "create": "신규 시리즈 생성",
    "title": "시리즈 목록",
    "client": "고객",
    "series": "시리즈",
    "chapters": "챕터",
    "lastUpdate": "마지막 업데이트 (일자)",
    "author": "작가",
    "byAuthor": "작가별",
    "chapterCount": countable({ zero: '챕터가 없습니다', one: '{count}개의 챕터가 있습니다', many: '{count}개의 챕터가 있습니다'}),
    "emptyState": {
      "title": "시리즈가 없습니다",
      "description": "새로운 시리즈를 시작합니다"
    },
    "deleteConfirmation": {
      "title": "해당 시리즈를 삭제하시겠어요?",
      "description": "삭제 후 되돌릴 수 없습니다"
    },
    "team": {
      "title": "팀",
      "updateModal": {
        "title": variables('{user}의 역할 변경하기'),
        "role": "부여된 역할"
      },
      "deleteModal": {
        "title": variables('{user}를 이 프로젝트에서 제외하시겠습니까?')
      },
      "invite": {
        "title": "파트너 초대",
        "email": "파트너 이메일",
        "role": "부여된 역할"
      }
    },
    "forms": {
      "create": {
        "title": "신규 시리즈 생성",
        "client": "고객",
        "seriesTitle": "시리즈 제목",
        "author": "작가",
        "errors": {
          "noRolesAttributed": 'You must set one of the role slots before creating a series.'
        } 
      },
      "update": {
        "title": "시리즈 변경",
        "client": "고객",
        "seriesTitle": "시리즈 제목",
        "author": "작가"
      }
    }
  },
  "chapters": {
    "create": "챕터 생성",
    "title": "챕터 목록",
    "lastUpdate": "마지막 업데이트 (일자)",
    "row": {
      "chapter": "챕터",
      "exportTranslation": "번역물 내보내기",
      "exportTypesetting": "레터링 내보내기",
      "downloadAssets": "리소스 다운로드"
    },
    "emptyState": {
      "title": "챕터가 없습니다",
      "description": "챕터를 추가하고 번역 시작하기"
    },
    "setStep": {
      "title": 'Set chapter step',
      'errors': {
        'noCollaborator': 'No collaborator has this role. Add someone to that role to set this chapter to this step.'
      }
    },
    "deleteConfirmation": {
      "title": "해당 챕터를 삭제하시겠습니까?",
      "description": "삭제 후 되돌릴 수 없습니다"
    },
    "forms": {
      "create": {
        "title": "새로운 챕터를 시작합니다",
        "chapterIndex": "챕터 번호",
        "chapterTitle": "챕터 제목",
        "sourcePages": "소스 페이지",
        "targetPages": "타깃 페이지",
        "guidelines1": "업로드하는 페이지는 파일명을 기준으로 가나다 순으로 정렬됩니다. 예시:",
        "guidelines2": "파일당 최대 길이",
        aboutTeam: variables('With your current team, the chapter will start at the {step} step')
      },
      "update": {
        "title": "신규 챕터 생성",
        "assetsLabel": "리소스 아카이브",
        "assetsDescription": "레터링에 필요한 리소스 zip 파일을 이곳에 공유하세요"
      },
      "export": {
        "title": "챕터 내보내기",
        "widthPreset": "넓이",
        "widthValue": "값",
        "splitIntoChunks": "페이지를 작은 이미지로 나누기",
        "splitBy": "~ 블럭으로 나누기",
        "fileFormat": "파일 포맷"
      }
    }
  },
  "user": {
    "role": {
      "translation": "번역",
      "proofreading": "감수",
      "typesetting": "레터링",
      "admin": "생성"
    }
  },
  "subscriptions": {
    "plans": {
      "brand": "ZOON",
      "brandPro": "ZOON Company",
      "single": "싱글",
      "duo": "듀오",
      "linguist": "Linguist",
      "graphist": "Letterer",
      "or": "또는"
    },
    "recurrence": {
      "singleMonth": "개월",
      "severalMonths": "개월",
      "year": "년",
      "forEach": "매"
    },
    "renewal": {
      "nextPayment": "다음 갱신일",
      "schedule": 'Schedule',
      "endsOn": "예정 만료일",
      "cancelled": "취소됨"
    },
    "taxesAtNextStep": "세금은 다음 단계에서 확정됩니다",
    "priceUpToQuantity": "금액 ~까지",
    "priceOverQuantity": "그룹 금액",
    "tax": "세금",
    "withoutTax": "VAT 별도",
    existingSubscriptionCredit: variables('{subscription} (Proration credit)'),
    newSubscriptionUpgrade: variables('{subscription} (Upgrade)'),
    "total": "총액",
    "indie": {
      "title": "개인 고객용 구독 플랜",
      "subscribe": {
        "cta": "구독하기",
        "title": "개인 고객용 플랜"
      },
      "upgrade": {
        "cta": "변경하기",
        "title": "구독 내역 업데이트",
        "newPlan": "신규 구독",
        "confirmImmediateBilling": "이 박스에 체크함으로써 구독 플랜 업그레이드를 위해 즉시 결제하는 것에 동의합니다",
        "confirmDowngrade": variables('이 박스에 체크함으로써 결제기한 만료일({date})에 선택한 플랜으로 변경 결제하는 것에 동의합니다')
      },
      "emptyState": "구독중인 플랜이 없습니다",
      "cancelConfirmation": {
        "title": variables('{plan} 구독 플랜을 취소하시겠습니까?'),
        "description": variables('{date}일자로 구독이 해지됩니다')
      }
    },
    "pro": {
      "title": "기업 고객용 구독 플랜",
      "subscribe": {
        "cta": "구독하기",
        "title": "기업 고객용 플랜",
        "submit": variables('{count}개의 라이센스 구독')
      },
      "upgrade": {
        "cta": "내 라이센스 변경하기",
        "title": "기업용 라이센스 업데이트",
        "submit": variables('{count}개의 라이센스로 변경'),
        "currentSubscription": "현재 구독 내역",
        "upgradedSubscription": "업데이트된 구독 내역"
      },
      "emptyState": "구독중인 기업용 플랜이 없습니다",
      "cancelConfirmation": {
        "title": "기업용 플랜을 취소하시겠습니까?",
        "description": variables('{date}일자로 라이센스가 만료됩니다')
      },
      "licenses": {
        "licenses": "라이센스",
        "emptyState": "부여된 라이센스가 없습니다",
        "count": countable({ zero: '라이센스가 없습니다', one: '{count}개의 라이센스', many: '{count}개의 라이센스'}),
        "noSlotsAvailable": "사용 가능한 슬롯이 없습니다",
        "grant": "부여하기",
        "grantSlot": "라이센스 부여",
        "deleteConfirmation": {
          "title": variables('{name}님에게 부여된 라이센스를 취소하시겠습니까?'),
          "description": "다시 사용 가능해진 슬롯은 새로운 파트너에게 부여할 수 있습니다",
          "confirm": "라이센스 취소"
        }
      }
    }
  },
  "account": {
    "profile": {
      "profile": "내 프로필",
      "preferredRole": "선호 직업군",
      "email": "이메일",
      "firstName": "이름",
      "lastName": "성",
      "language": "언어",
      "interface": "인터페이스",
      "theme": {
        "light": "라이트",
        "dark": "다크"
      },
      "logout": "로그아웃 하기"
    },
    "invoices": {
      "invoices": "인보이스"
    },
    "contacts": {
      "contacts": "내 파트너 목록"
    }
  },
  "errors": {
    "notFound": {
      "title": "페이지를 찾을 수 없습니다",
      "description": "존재하지 않는 컨텐츠입니다",
      "back": "메인으로 돌아가기"
    },
    "fatalError": {
      "title": "오류가 발생했습니다",
      "description": "기술팀에 자세한 오류 정보를 전달했습니다. 신속한 해결을 위해 최선을 다하겠습니다.",
      "back": "메인으로 돌아가기"
    }
  },
  "footer": {
    "legal": 'Terms and Conditions'
  }
}

export default krDictionnary