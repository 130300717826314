import { createSignal } from 'solid-js'
import { settings } from '~/services/settings/settings'
import enDictionnary from '~/translations/en-dictionnary'
import frDictionnary from '~/translations/fr-dictionnary'
import krDictionnary from '~/translations/kr-dictionnary'
import { Language } from '~/translations/types'

const [language, setLanguage] = createSignal<Language>(settings().language)

const languages = {
  fr: {
    label: 'Français',
    dictionary: frDictionnary
  },
  en: {
    label: 'English',
    dictionary: enDictionnary
  },
  kr: {
    label: '한국어',
    dictionary: krDictionnary
  }
}

const translations = () => {
  const lang = language()
  const dictionnary = lang in languages ? languages[lang as keyof typeof languages].dictionary : frDictionnary
  return dictionnary
}

export {
  translations,
  language,
  setLanguage,
  languages
}