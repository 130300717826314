import { countable, variables } from '~/translations/utils'

const frDictionnary = {
  header: {
    menu: {
      series: 'Séries',
      clients: 'Clients',
      account: 'Compte',
    },    
    subscribe: 'Sʼabonner',
    subscription: 'Abonnement'
  },
  notifications: {
    new: 'Nouveau',
    notifications: countable({
      zero: 'Aucune notification',
      one: '{count} notification',
      many: '{count} notifications'
    }),
    default: 'Notification',
    invite: 'Invitation',
    inviteConfirmation: {
      title: variables('Rejoindre le projet {title} ?'),
      confirm: 'Rejoindre',
      reject: 'Refuser'
    },
    update: 'Mise à jour'
  },
  general: {
    actions: {
      add: 'Ajouter',
      create: 'Créer',
      edit: 'Éditer',
      confirm: 'Confirmer',
      delete: 'Supprimer',
      invite: 'Inviter',
      export: 'Exporter',
      stop: 'Arrêter',
      insert: 'Insérer',
      send: 'Envoyer',
      cancel: 'Annuler',
      display: 'Afficher',
      save: 'Sauvegarder',
      copy: 'Copier',
      import: 'Importer',
      filter: 'Filtrer',
      search: 'Rechercher',
      fold: 'Replier',
      replace: 'Remplacer',
      replaceBy: 'Remplacer par',
      download: 'Télécharger',
      updated: 'Mis à jour',
      back: 'Retour',
      prev: 'Précédent',
      next: 'Suivant',
    },
    search: {
      noResult: 'Aucun résultat',
      resultIndex: variables('Résultat {current}/{total}'),
      replaceConfirmation: countable({
        zero: 'Aucune entrée ne sera remplacée',
        one: 'Vous allez remplacer {count} entrée',
        many: 'Vous allez remplacer {count} entrées',
      }),
    },
    fields: {
      findInContacts: 'Trouver dans mes contacts',
      optional: 'optionnel',
    },
    errors: {
      noEmpty: variables('{field} doit être renseigné')
    }
  },
  login: {
    title: 'Connexion',
    email: 'Email',
    emailPlaceholder: 'email@exemple.com',
    password: 'Mot de passe',
    login: 'Connexion',
    register: 'Créer un compte',
    forgotPassword: 'Jʼai oublié mon mot de passe'
  },
  register: {
    title: 'Créer un compte',
    register: 'Créer un compte',
    password: 'Mot de passe',
    confirmPassword: 'Confirmez votre Mot de passe',
    alreadyHaveAccount: 'Jʼai déjà un compte',
  },
  forgotPassword: {
    title: 'Réinitialiser mon mot de passe',
    instructions: 'Un email avec les instructions pour réinitialiser votre mot de passe vous a été envoyé.',
  },
  resetPassword: {
    title: 'Réinitialiser mon mot de passe',
    newPassword: 'Nouveau Mot de passe',
    success: 'Votre mot de passe a été modifié avec succès.',
  },
  onboarding: {
    welcome: 'Bienvenue !',
    createPassword: 'Créer votre mot de passe',
    invitePeople: 'Invitez des partenaires',
    done: 'Terminé',
    welcome1: 'Vous êtes prêt à rejoindre lʼunivers Zoon',
    welcome2: 'Créez votre première série ou rejoignez le projet dʼun partenaire !',
    begin: 'Commencer',
  },
  dashboard: {
    user: {
      welcome: 'Bienvenue',
      currentRole: 'Role actuel'
    },
    contacts: {
      title: 'Partenaires',
      savedContacts: {
        title1: 'Votre liste de',
        title2: 'Favoris',
        emptyState: 'Aucun favori pour lʼinstant',
        remove: 'Enlever des favoris'
      },
      recentContacts: {
        title1: 'Durant vos derniers projets',
        title2: 'Contacts récents',
        add: 'Ajouter aux favoris'
      },
      invite: {
        title: 'Ajouter un partenaire',
        email: 'Email du partenaire',
        emailDescription: 'Ajoutez vos contacts avec leur email Zoon',
        errorNoResponse: 'Ce partenaire nʼexiste pas ou nʼaccepte pas les invitations',
        errorAlreadyExists: 'Vous avez déjà enregistré ce partenaire',
      },
    },
    wip: {
      title: 'Chapitres en cours',
      series: 'Série',
      chapter: 'Chapitre',
      progress: 'Progression',
      lastUpdate: 'Dernière MAJ.',
      emptyState: {
        title: 'Aucun chapitre en cours',
        description: 'Créez une série ou ajoutez un chapitre à vos séries en cours pour suivre leur progrès ici.'
      }
    },
    discord: {
      title1: 'Rejoignez-nous sur',
      title2: 'Discord'
    }
  },
  loading: {
    default: 'Chargement',
    uploadingImages: 'Téléversement des images',
    transcription: 'Transcription du document',
    transcriptionImage: 'Image',
    transcriptionOn: 'sur',
    exportingTranslations: 'Export des traductions'
  },
  editor: {
    translation: {
      header: {
        originalWordCount: 'VO',
        targetWordCount: 'VF',
        words: 'mots',
        chars: 'char.',
      },
      filterOptions: {
        all: 'Tout',
        corrections: 'Corrections',
        comments: 'Commentaires',
        onomatopoeias: 'Onomatopées',
      },
      entries: {
        markAsOnomatopoeia: 'Marquer comme onomatopée',
        originalText: 'Texte original',
        translationText: 'Traduction',
        deleteConfirmation: {
          title: 'Supprimer cette entrée de traduction ?',
        },
        emptyState: {
          label: 'Aucune traduction',
          description: 'Dessinez une zone sur le document ou cliquez sur une bulle pour créer votre première traduction'
        },
        filteredEmptyState: {
          label: 'Aucune traduction correspondante',
          description: 'Changez votre filtre pour voir le reste des traductions'
        },
      }
    },
    typesetting: {
      exampleText: 'Text dʼexemple',
      textStyle: {
        notSaved: 'Style non enregistré',
        deleteConfirmation: {
          title: 'Supprimer le style de texte ?'
        },
        emptyState: {
          title: 'Aucun style de texte'
        },
        form: {
          create: {
            title: 'Créer une nouveau style de texte',
            name: 'Nom du style',
            placeholder: 'Dialogue/Défaut',
            defaultValue: 'Style/Variation1'
          }
        }
      },
      fonts: {
        missingFont: 'Police manquante'
      },
      fields: {
        fontSize: 'Taille',
        uppercase: 'Majuscules',
        superscript: 'Superscript',
        lineHeight: 'Interligne',
        letterSpacing: 'Caractères',
        color: 'Couleur',
        gradient: 'Dégradé',
        addGradient: 'Ajouter un dégradé',
        removeGradient: 'Supprimer le dégradé',
        strokes: 'Bordures',
        shadows: 'Ombres',
      }
    },
    comments: {
      view: 'Voir les commentaires',
      add: 'Ajouter un commentaire',
      placeholder: 'Votre commentaire',
      markAsResolved: 'Marquer comme résolu',
      count: countable({
        zero: 'Aucun commentaire',
        one: '{count} commentaire',
        many: '{count} commentaires'
      }),
      deleteConfirmation: {
        title: 'Supprimer ce commentaire ?',
        description: 'Cette suppression est définitive'
      }
    },
    io: {
      exportTranslations: 'Exporter les traductions',
      importTranslations: 'Importer des traductions',
      exportTypesetting: 'Exporter le lettrage',
      documentation: 'Documentation',
      preview: 'Aperçu',
      forms: {
        importTranslations: {
          title: 'Importer une traduction',
          description: 'Votre traduction doit être importée depuis un fichier texte respectant notre format.',
          translationsFile: 'Fichier de traduction',
          overwriteTranslations: 'Écraser les traductions',
          overwriteTypesets: 'Écraser les lettrages'
        }
      }
    },
    page: 'page',
    steps: {
      translation: 'Traduction',
      proofreading: 'Correction',
      typesetting: 'Lettrage',
      typeProofreading: 'Correction Lettrage',
      validation: 'Validation',
      completed: 'Terminé',
      archived: 'Archivé',
    },
    resumeStep: {
      translation: 'Continuer la Traduction',
      proofreading: 'Continuer la Correction',
      typesetting: 'Continuer le Lettrage',
      validation: 'Validation de chapitre',
      completed: 'Aperçu'
    },
    stepConfirmations: {
      validateTranslation: {
        title: 'Confirmer la traduction',
        description: 'Le chapitre sera assigné à la correction.'
      },
      validateProofreading: {
        title: 'Confirmer la correction',
        description: 'Le chapitre sera assigné au lettrage.'
      },
      backToTranslation: {
        title: 'Renvoyer à la traduction',
        description: 'Le chapitre sera assigné à la traduction.'
      },
      validateTypesetting: {
        title: 'Confirmer le lettrage',
        description: 'Le chapitre sera assigné à la correction du lettrage.'
      },
      validateTypeProofreading: {
        title: 'Confirmer la correction',
        description: 'Le chapitre passera en validation finale.'
      },
      backToTypesetting: {
        title: 'Renvoyer au lettrage',
        description: 'Le chapitre sera assigné au lettrage.'
      },
      validateValidation: {
        title: 'Valider le chapitre',
        description: "Le chapitre sera marqué comme terminé. Vous pourrez l'exporter sous différents formats."
      }
    }
  },
  series:{
    create: 'Créer une série',
    title: 'Liste des séries',
    client: 'Client',
    series: 'Série',
    chapters: 'Chapitres',
    lastUpdate: 'Dernière MAJ.',
    author: 'Auteur',
    byAuthor: 'par',
    chapterCount: countable({
      zero: 'Aucun chapitre',
      one: '{count} chapitre',
      many: '{count} chapitres'
    }),
    emptyState: {
      title: 'Aucune série',
      description: 'Commencez une nouvelle série'
    },
    deleteConfirmation: {
      title: 'Supprimer cette série ?',
      description: 'Cette suppression est définitive'
    },
    team: {
      title: 'Équipe',
      updateModal: {
        title: variables('Modifier le role de {user}'),
        role: 'Rôle attribué'
      },
      deleteModal: {
        title: variables('Supprimer {user} du projet ?')
      },
      invite: {
        title: 'Inviter un partenaire',
        email: 'Email du partenaire',
        role: 'Rôle attribué'
      },
    },
    forms: {
      create: {
        title: 'Créer une nouvelle série',
        client: 'Client',
        seriesTitle: 'Titre de la série',
        author: 'Auteur',
        errors: {
          noRolesAttributed: 'Vous devez attribuer au moins un role avant de créer une série.'
        } 
      },
      update: {
        title: 'Modifier la série',
        client: 'Client',
        seriesTitle: 'Titre de la série',
        author: 'Auteur',
      }
    }
  },
  chapters:{
    create: 'Créer un chapitre',
    title: 'Liste des chapitres',
    lastUpdate: 'Dernière MAJ.',
    row: {
      chapter: 'Chapitre',
      exportTranslation: 'Exporter la traduction',
      exportTypesetting: 'Exporter le lettrage',
      downloadAssets: 'Télécharger les ressources',
    },
    emptyState: {
      title: 'Aucun chapitre',
      description: 'Commencez la traduction en ajoutant un chapitre'
    },
    setStep: {
      title: 'Modifier lʼétape de travail',
      errors: {
        noCollaborator: 'Aucun collaborateur nʼest attribué à ce role. Ajoutez un collaborateur à ce role pour passer le chapitre à cette étape.'
      }
    },
    deleteConfirmation: {
      title: 'Supprimer ce chapitre ?',
      description: 'Cette suppression est définitive'
    },
    forms: {
      create: {
        title: 'Créer un nouveau chapitre',
        chapterIndex: 'Numéro de chapitre',
        chapterTitle: 'Titre du chapitre',
        sourcePages: 'Pages source',
        targetPages: 'Pages cible',
        guidelines1: 'Vos pages seront ordonnées alphabétiquement, dʼaprès leur nom de fichier. Exemple :',
        guidelines2: 'Hauteur maximum par fichier',
        aboutTeam: variables('Avec votre équipe actuelle, le chapitre commencera à lʼétape {step}')
      },
      update: {
        title: 'Créer un nouveau chapitre',
        assetsLabel: 'Archive de ressources',
        assetsDescription: 'Partagez ici un zip des ressources nécessaires pour le lettrage'
      },
      export: {
        title: 'Exporter le chapitre',
        widthPreset: 'Largeur',
        widthValue: 'Valeur',
        splitIntoChunks: 'Découper les pages en petites images',
        splitBy: 'Découper par blocs de',
        fileFormat: 'Format de fichier',
      }
    }
  },
  user: {
    role: {
      translation: 'Traduction',
      proofreading: 'Correction',
      typesetting: 'Lettrage',
      admin: 'Création',
    }
  },
  subscriptions: {
    plans: {
      brand: 'ZOON',
      brandPro: 'ZOON entreprise',
      single: 'Unique',
      duo: 'Duo',
      linguist: 'Linguiste',
      graphist: 'Graphiste',
      or: 'ou'
    },
    recurrence: {
      singleMonth: 'mois',
      severalMonths: 'mois',
      year: 'an',
      forEach: 'par',
    },
    renewal:{
      nextPayment: 'Prochain renouvellement',
      schedule: 'Planning',
      endsOn: 'Se terminera le',
      cancelled: 'Annulé'
    },
    taxesAtNextStep: 'Les taxes seront déterminées à la prochaine étape.',
    priceUpToQuantity: 'Prix jusquʼà',
    priceOverQuantity: 'Prix de groupe',
    tax: 'Taxes',
    withoutTax: 'HT',
    existingSubscriptionCredit: variables('Prorata {subscription}'),
    newSubscriptionUpgrade: variables('Mise à jour vers {subscription}'),
    total: 'Total',
    indie: {
      title: 'Abonnements particuliers',
      subscribe: {
        cta: 'Sʼabonner',
        title: 'Nos tarifs indépendants'
      },
      upgrade: {
        cta: 'Modifier',
        title: 'Mettre à jour votre abonnement',
        newPlan: 'Nouvel abonnement',
        confirmImmediateBilling: 'En cochant cette case, vous acceptez dʼêtre facturé immédiatement pour passer au plan supérieur.',
        confirmDowngrade: variables('En cochant cette case, vous acceptez de passer au plan sélectionné à la fin de votre période de facturation ({date}).')
      },
      emptyState: 'Aucun abonnement',
      cancelConfirmation: {
        title: variables('Annuler votre abonnement {plan} ?'),
        description: variables('Lʼabonnement prendra fin à partir du {date}')
      },
    },
    pro: {
      title: 'Abonnements entreprise',
      subscribe: {
        cta: 'Sʼabonner',
        title: 'Nos tarifs entreprise',
        submit: variables('Sʼabonner pour {count} licenses')
      },
      upgrade: {
        cta: 'Modifier mes licenses',
        title: 'Mettre à jour vos licenses entreprise',
        submit: variables('Changer pour {count} licenses'),
        currentSubscription: 'Abonnement actuel',
        upgradedSubscription: 'Abonnement mis à jour'
      },
      emptyState: 'Aucun abonnement dʼentreprise',
      cancelConfirmation: {
        title: 'Annuler votre abonnement entreprise ?',
        description: variables('Les licenses prendront fin le {date}')
      },
      licenses: {
        licenses: 'Licenses',
        emptyState: 'Aucune license attribuée',
        count: countable({
          zero: 'Aucune license',
          one: '{count} license',
          many: '{count} licenses',
        }),
        noSlotsAvailable: 'Aucun slot restant',
        grant: 'Attribuer',
        grantSlot: 'Attribuer une license',
        deleteConfirmation: {
          title: variables('Retirer la license attribuée à {name} ?'),
          description: 'Le slot redeviendra disponible pour assigner un nouveau partenaire.',
          confirm: 'Retirer la license'
        }
      }
    }
  },
  account: {
    profile: {
      profile: 'Mon profil',
      preferredRole: 'Rôle préféré',
      email: 'Email',
      firstName: 'Prénom',
      lastName: 'Nom',
      language: 'Langue',
      interface: 'Interface',
      theme: {
        light: 'Clair',
        dark: 'Sombre'
      },
      logout: 'Se déconnecter',
    },
    invoices: {
      invoices: 'Factures'
    },
    contacts: {
      contacts: 'Mes partenaires'
    }
  },
  errors: {
    notFound: {
      title: 'Page introuvable',
      description: 'Ce contenu nʼexiste pas.',
      back: 'Retour à lʼapplication'
    },
    fatalError: {
      title: 'Une erreur est survenue',
      description: 'Les détails ont été remontés aux équipes techniques, nous allons faire tout notre possible pour corriger cet incident au plus vite.',
      back: 'Retour à lʼapplication'
    }
  },
  footer: {
    legal: 'Conditions Générales dʼutilisation'
  }
}

export default frDictionnary